<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 reception-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="reception-header icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: backLinkName }">
            <button>
              <icon icon="#cx-hea1-arrow-left" />
            </button>
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("services.service-types.service-types") }}
        </div>
        <template v-slot:right> </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper applications-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="displayLabelName('global.search.search')"
          />
          <div class="icon search"><svg-icon icon="lense"></svg-icon></div>
        </div>
      </section>
    </template>
    <ul class="clebex-item-section pill">
      <li
        class="clebex-item-section-item"
        v-for="serviceItemType in listOfTypes"
        :key="serviceItemType.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="setSelectedServiceItemType(serviceItemType)"
        >
          <span class="label">
            <span class="highlight">
              {{ serviceItemType.name }}
            </span>
          </span>
          <span class="follow-up-icons">
            <span
              class="follow-up-icon-item active"
              v-if="serviceType && serviceType.id === serviceItemType.id"
            >
              <icon icon="#cx-app1-checkmark"></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";

export default {
  name: "ServiceItemTypes",
  data() {
    return {
      searchQuery: ""
    };
  },
  created() {
    this.getServiceTypes();
    if (
      !this.serviceType &&
      this.serviceItem &&
      this.serviceItem.data &&
      this.serviceItem.data.service_type
    ) {
      this.setServiceType(this.serviceItem.data.service_type);
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  computed: {
    ...mapState("service", ["serviceTypes", "serviceType", "serviceItem"]),
    listOfTypes() {
      const query = this.searchQuery;

      if (
        this.serviceTypes &&
        this.serviceTypes.length &&
        this.serviceTypes.length
      ) {
        if (query && query.length > 1) {
          return this.serviceTypes.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        }
        return this.serviceTypes;
      }
      return null;
    }
  },
  methods: {
    ...mapActions("service", ["setServiceType", "getServiceTypes"]),
    setSelectedServiceItemType(serviceType) {
      this.setServiceType(serviceType);
      this.$router.push({ name: this.backLinkName });
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
